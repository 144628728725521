import config from './config';

function setJwt(token: string) {
  localStorage.setItem(config.jwt_key, token);
}

function removeJwt() {
  localStorage.removeItem(config.jwt_key);
}

export { setJwt, removeJwt };
