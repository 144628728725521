import { Effect, Reducer, history } from 'umi';
import { message } from 'antd';
import { loginByPhone, logout } from '@/services/login';
import { isAuth } from '@/services/center';
import { ConnectState, LoginUserInfoState } from './connect.d';
import {
  sendLoginPhoneCaptcha,
  quickLoginByPhone,
  loginByPassword,
} from '@/services/login';
import { setJwt } from '@/utils/auth';

export interface LoginModelState {
  jwt: string;
  userInfo: LoginUserInfoState;
  isError: boolean;
}

export interface LoginModelType {
  namespace: 'login';
  state: LoginModelState;
  effects: {
    getUserInfo: Effect;
    loginByPhone: Effect;
    loginByPwd: Effect;
    getPhoneCaptch: Effect;
    logout: Effect;
  };
  reducers: {
    save: Reducer<LoginModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<LoginModelState>;
  };
}

const LoginModel: LoginModelType = {
  namespace: 'login',
  state: {
    jwt: '',
    userInfo: {
      id: '',
      name: '',
      role: '',
    },
    isError: false,
  },
  effects: {
    *loginByPhone({ payload }, { call, put, select }) {
      const response = yield call(quickLoginByPhone, payload);
      if (response?.state === 1000) {
        setJwt(response.data);
        message.success('登录成功');
        history.replace('/');
        location.reload();
      } else {
        message.error(response.message);
      }
      // const response = yield call(loginByPhone, { ...payload });
      // const { jwt = '' } = response && response.data;
      // if (response.state === 1000) {
      //   yield put({
      //     type: 'save',
      //     payload: {
      //       jwt: response.data,
      //     },
      //   });
      //   localStorage.setItem('skyeye_token', JSON.stringify(jwt));

      //   //TODO Ying 需要同步修改 GlobalModelState 中的 isUserAuth
      //   const { isUserAuth } = yield select(
      //     (state: ConnectState) => state.global,
      //   );
      //   // const data = yield call(isAuth, { ...payload, jwt });
      //   // console.log(data);

      //   yield put({
      //     type: 'save',
      //     payload: {
      //       isError: true,
      //     },
      //   });

      //   message.success('登录成功！');
      //   history.replace('/');
      // } else {
      //   yield put({
      //     type: 'save',
      //     payload: {
      //       isError: true,
      //     },
      //   });
      // }
    },
    *loginByPwd({ payload }, { call, put, select }) {
      const response = yield call(loginByPassword, payload);
      if (response?.state === 1000) {
        setJwt(response.data);
        message.success('登录成功');
        history.replace('/');
        location.reload();
      } else {
        message.error(response.message);
      }
    },
    *getPhoneCaptch({ payload }, { call, put, select }) {
      const response = yield call(sendLoginPhoneCaptcha, payload);
      if (response?.state === 1000) {
        message.success('手机验证码发送成功');
      } else {
        message.error(response.message);
      }
    },
    *getUserInfo({ payload }, { call, put, select }) {
      const { name } = yield select((state: ConnectState) => state.global);
      const data = yield call(loginByPhone, { ...payload, name });
      yield put({
        type: 'save',
        payload: {
          userInfo: data,
        },
      });
    },
    *logout(_, { call }) {
      const response = yield call(logout);
      if (response?.status === 'ok') {
        localStorage.removeItem('skyeye_token');
        history.replace({
          pathname: '/login',
          search: `timestamp=${new Date().getTime()}`,
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // 启用 immer 之后
    // save(state, action) {
    //   state.name = action.payload;
    // },
  },
};

export default LoginModel;
