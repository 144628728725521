import { Effect, Reducer, Subscription } from 'umi';
import { isAuth } from '@/services/center';
import { LoginUserInfoState } from './connect.d';

export interface GlobalModelState {
  name: string;
  userInfo: LoginUserInfoState;
  isUserAuth: boolean;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    isAuth: Effect;
  };
  reducers: {
    save: Reducer<GlobalModelState>;
    // 启用 immer 之后
    // save: ImmerReducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}

const GlobalModel: GlobalModelType = {
  namespace: 'global',
  state: {
    name: '',
    userInfo: {
      id: '',
      name: '',
    },
    isUserAuth: false,
  },
  effects: {
    *isAuth({ payload }, { call, put }) {
      const response = yield call(isAuth);
      if (response.state === 1000) {
        yield put({
          type: 'save',
          payload: {
            isUserAuth: response.data,
          },
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // 启用 immer 之后
    // save(state, action) {
    //   state.name = action.payload;
    // },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // const reg = /^\/login|\/register/g;
        // if (!reg.test(pathname)) {
        const reg = /^\//g;
        if (reg.test(pathname)) {
          dispatch({
            type: 'isAuth',
          });
        }
      });
    },
  },
};

export default GlobalModel;
