import request from '@/utils/request';

export async function isAuth() {
  return request('/api/isAuth', {
    method: 'POST',
  });
}

export async function sendAuthPhoneCaptcha() {
  return request('/api/sendAuthPhoneCaptcha', {
    method: 'POST',
  });
}
export async function sendBindPhoneCaptcha(params: object) {
  return request('/api/sendBindPhoneCaptcha', {
    method: 'POST',
    data: params,
  });
}

export async function sendEmailCaptcha(params: object) {
  return request('/api/emailCaptcha', {
    method: 'POST',
    data: params,
  });
}

export async function authPhoneCaptcha(params: object) {
  return request('/api/authPhoneCaptcha', {
    method: 'POST',
    data: params,
  });
}

export async function getPersonalInfo() {
  return request('/api/getPersonalInfo', {
    method: 'POST',
  });
}

export async function modifyMobile(params: object) {
  return request('/api/modifyMobile', {
    method: 'POST',
    data: params,
  });
}

export async function modifyAvatar(param: string) {
  return request('/api/modifyAvatar/' + param, {
    method: 'GET',
  });
}

export async function modifyPwd(params: object) {
  return request('/api/modifyPwd', {
    method: 'POST',
    data: params,
  });
}

export async function modifyEmail(params: object) {
  return request('/api/modifyEmail', {
    method: 'POST',
    data: params,
  });
}

export async function modifyBaseInfo(params: object) {
  return request('/api/modifyBaseInfo', {
    method: 'POST',
    data: params,
  });
}
