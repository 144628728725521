import request from '@/utils/request';
//父评论id为0表示根评论
export interface AddCommentProps {
  content: string;
  parentCommentId: number;
  testcaseId: number;
}

export interface GetCommentProps {
  testcaseId: number;
}

export interface LikeCommentProps {
  commentId: number;
  islike: boolean;
}

// 增加用户评论信息
export async function addComment(data: AddCommentProps) {
  return request('/api/addComment', {
    method: 'POST',
    data,
  });
}

// 获取用户评论信息
export async function getComment(data: GetCommentProps) {
  return request('/api/getComment', {
    method: 'POST',
    data,
  });
}
// 评论点赞
export async function likeComment(data: LikeCommentProps) {
  return request('/api/likeComment', {
    method: 'POST',
    data,
  });
}
// 获取用例trem接口信息
export async function getTestcaseInfo(data: GetCommentProps) {
  return request('/api/getTestcaseInfo', {
    method: 'GET',
    params: data,
  });
}

// 上传测试程序
export async function uploadTestProgram(data: any) {
  let formData = new FormData();
  formData.append('file', data);
  return request('/api/upload', {
    method: 'POST',
    requestType: 'form',
    data: formData,
  });
}

// 获取已上传测试程序
export async function findUserbin(data: GetCommentProps) {
  return request('/api/findUserbin', {
    method: 'GET',
    params: data,
  });
}

// 删除已上传测试程序
export async function deleteUserbin(data: { binFileId: number }) {
  return request('/api/deleteUserbin', {
    method: 'GET',
    params: data,
  });
}
