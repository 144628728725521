// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'C:/Users/11130/Desktop/yyj/Zeus/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx')}),
    "routes": [
      {
        "path": "/about",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__index' */'@/pages/about/index.tsx')})
      },
      {
        "path": "/center",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__center__index' */'@/pages/center/index.tsx')})
      },
      {
        "path": "/forum",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__forum__index' */'@/pages/forum/index.tsx')})
      },
      {
        "path": "/home",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index.tsx')})
      },
      {
        "path": "/imageDetail/:id",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__imageDetail__id' */'@/pages/imageDetail/[id].tsx')})
      },
      {
        "path": "/images",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__images__index' */'@/pages/images/index.tsx')})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index.tsx')})
      },
      {
        "path": "/law",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__law__index' */'@/pages/law/index.tsx')})
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index.tsx')})
      },
      {
        "path": "/register",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__index' */'@/pages/register/index.tsx')})
      },
      {
        "path": "/soc",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__soc__index' */'@/pages/soc/index.tsx')})
      },
      {
        "path": "/support",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__support__index' */'@/pages/support/index.tsx')})
      },
      {
        "path": "/console",
        "routes": [
          {
            "path": "/console",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__console__index' */'@/pages/console/index.tsx')})
          },
          {
            "path": "/console/myFile",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__console__myFile__index' */'@/pages/console/myFile/index.tsx')})
          },
          {
            "path": "/console/mySource",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__console__mySource__index' */'@/pages/console/mySource/index.tsx')})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__console___layout' */'@/pages/console/_layout.tsx')})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
