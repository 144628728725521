import { Effect, Reducer, Subscription } from 'umi';
import { getHotCores } from '@/services/home';

export interface HomeModelState {
  hotModels: [];
}

export interface HomeModelType {
  namespace: 'home';
  state: HomeModelState;
  effects: {
    getHotCores: Effect;
  };
  reducers: {
    save: Reducer<HomeModelState>;
  };
  subscriptions: { setup: Subscription };
}

const HomeModel: HomeModelType = {
  namespace: 'home',

  state: {
    hotModels: [],
  },

  effects: {
    *getHotCores({}, { put, call }) {
      const response = yield call(getHotCores);
      yield put({
        type: 'save',
        payload: {
          hotModels: response.data,
        },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/home') {
          dispatch({
            type: 'getHotCores',
          });
        }
      });
    },
  },
};

export default HomeModel;
