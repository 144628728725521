const config = {
  product: 'SkyEye Web',
  copyright: '©2022 浙江迪捷软件科技有限公司',
  icp: '浙ICP备2021007020号',
  police: '浙公网安备33060202000978号',
  logo: '/logo.png',
  search_placeholder: '搜索',
  jwt_key: 'skyeye_token',
  /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
  i18n: {
    /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
    languages: [
      {
        key: 'en',
        title: 'English',
        flag: '/america.svg',
      },
      {
        key: 'zh',
        title: '中文',
        flag: '/china.svg',
      },
    ],
    defaultLanguage: 'zh',
  },
  apisWithToken: [
    '/api/isAuth',
    '/api/sendAuthPhoneCaptcha',
    '/api/sendBindPhoneCaptcha',
    '/api/emailCaptcha',
    '/api/authPhoneCaptcha',
    '/api/getPersonalInfo',
    '/api/logoff',
    '/api/modifyAvatar',
    '/api/modifyBaseInfo',
    '/api/modifyMobile',
    '/api/modifyPwd',
    '/api/modifyBaseInfo',
    '/api/modifyEmail',
    '/api/upload',
  ],
};
export default config;
