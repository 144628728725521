import { Effect, Reducer, history } from 'umi';
import { sendRegisterPhoneCaptcha, registerAccont } from '@/services/register';
import { message } from 'antd';

// export interface RegisterForm {
//     username: string;
//     password: string;
//     confirmPassword: string;
//     phone: string;
//     captcha: string;
// }

// export interface RegisterFormItem {
//     value: string;
//     verifyFun: Function | null;
//     state: boolean;
// }

export interface RegisterModelState {
  username: string;
  password: string;
  confirmPassword: string;
  phone: string;
  captcha: string;
}

export interface RegisterModelType {
  namespace: 'register';
  state: RegisterModelState;
  effects: {
    registerAccount: Effect;
    getVerifyCode: Effect;
  };
  reducers: {
    save: Reducer<RegisterModelState>;
  };
}

const RegisterModel: RegisterModelType = {
  namespace: 'register',
  state: {
    username: '',
    password: '',
    confirmPassword: '',
    phone: '',
    captcha: '',
  },

  effects: {
    *registerAccount({ payload }, { call, put }) {
      const response = yield call(registerAccont, payload);

      if (response?.state === 1000) {
        message.success('注册成功');
        history.replace('/login');
      } else {
        message.error(response.message);
      }
    },
    *getVerifyCode({ payload, callback }, { call, put }) {
      const response = yield call(sendRegisterPhoneCaptcha, payload);
      // console.log(response);
      if (response?.state === 1000) {
        message.success('发送验证码成功');
        callback(true);
      } else {
        message.error('发送验证码失败');
        callback(false);
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export default RegisterModel;
