import { Effect, Reducer, Subscription } from 'umi';
import { getSocs, Soc, SupportList } from '@/services/soc';
import { message } from 'antd';
import tip from '@/utils/string';

export interface SocModelState {
  socs: Array<Soc>;
}

export interface SocModelType {
  namespace: 'soc';
  state: SocModelState;
  effects: {
    getSocs: Effect;
  };
  reducers: {
    save: Reducer<SocModelState>;
  };
  subscriptions: {
    setup: Subscription;
  };
}

const SocModel: SocModelType = {
  namespace: 'soc',

  state: {
    socs: [],
  },

  effects: {
    *getSocs({ payload }, { call, put }) {
      const result = yield call(getSocs);
      if (result?.state === 1000) {
        yield put({
          type: 'save',
          payload: {
            socs: result.data,
          },
        });
      } else {
        message.error(result?.message || tip.REQUEST_ERROR);
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/soc') {
          dispatch({
            type: 'getSocs',
          });
        }
      });
    },
  },
};

export default SocModel;
