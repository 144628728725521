import request from '@/utils/request';

export interface ProcessorCoreReqType {
  instructionsArchIds: [];
}

export interface FiltrationCoresReqType {
  instructionsArchIds: [];
  processorCoreIds: [];
}

export async function getInstructionsArch() {
  return request('/api/getInstructionsArch');
}

export async function getArchMappingCores() {
  return request('/api/getArchMappingCores');
}

export async function getProcessorCore(params: ProcessorCoreReqType) {
  return request('/api/getProcessorCore', {
    method: 'POST',
    data: params,
  });
}

export async function getFiltrationCores(params: FiltrationCoresReqType) {
  return request('/api/getFiltrationCores', {
    method: 'POST',
    data: params,
  });
}
