import request, { Base } from '@/utils/request';

export interface Soc {
  id: number;
  imgId: number;
  name: string;
  cpu: string;
  manufacturer: string;
  osSupport: string;
  socIntroduce: string;
  deviceSupport: Array<SupportList>;
  updateTime: number;
  websiteLink: string;
}

export interface SupportList {
  name: string;
  isSupport: boolean;
  describe: string;
  isCustom: boolean;
  className: string;
}

export function getSocs(): Promise<Base<Soc>> {
  return request('/api/getSocs', {
    method: 'GET',
  });
}
