import request from '@/utils/request';

export interface CaptchaParams {
  phone: string;
}

export interface RegisterParams {
  captcha: string;
  confirmPassword: string;
  email?: string;
  password: string;
  phone: string;
  username: string;
}

export async function sendRegisterPhoneCaptcha(data: CaptchaParams) {
  return request('/api/sendRegisterPhoneCaptcha', {
    method: 'POST',
    data,
  });
}

export async function registerAccont(data: RegisterParams) {
  return request('/api/register', {
    method: 'POST',
    data,
  });
}
