import request from '@/utils/request';

export interface LoginParamsType {
  phone: string;
  password: string;
}

export interface Token {
  jwt: string;
}

export interface PhoneCaptchaProps {
  phone: string;
}

export interface PhoneLoginProps {
  captcha: string;
  password: string;
  phone: string;
  username: string;
}

export interface PasswordLoginProps {
  captcha: string;
  password: string;
  phone: string;
  username: string;
}

export async function loginByPhone(params: LoginParamsType) {
  return request('/api/loginByPhone', {
    method: 'POST',
    data: params,
  });
}

export async function logout(): Promise<any> {
  return request('/api/logout');
}

// 发送登录手机验证码
export async function sendLoginPhoneCaptcha(data: PhoneCaptchaProps) {
  return request('/api/sendLoginPhoneCaptcha', {
    method: 'POST',
    data,
  });
}
// 手机快捷登录接口
export async function quickLoginByPhone(data: PhoneLoginProps) {
  return request('/api/quickLoginByPhone', {
    method: 'POST',
    data,
  });
}
// 账号密码登录接口
export async function loginByPassword(data: PasswordLoginProps) {
  return request('/api/LoginByPassword', {
    method: 'POST',
    data,
  });
}
