export default {
  BIND: '绑定',
  UNBIND: '未绑定',
  MODIFY: '修改',
  BIND_EMAIL_TIP:
    '邮箱绑定后可用来接收SkyEye Web发送的各类系统或服务通知，可用于登录。',
  REQUEST_ERROR: '请求错误',
  SHARE_BY_WEIXIN: '微信扫一扫分享',
  CURRENT_IMAGE_ADDRESS: '当前镜像地址',
  SHARE_OTHER_BOARD: '分享其他平台',
  COPY: '复制',
};

export const isEmptyString = (s: any): boolean => {
  if (typeof s === 'string' && s !== undefined && s !== '') {
    return false;
  }
  return true;
};
