import { Effect, Reducer, Subscription } from 'umi';
import {
  getPersonalInfo,
  modifyAvatar,
  modifyMobile,
  modifyPwd,
  modifyEmail,
  sendAuthPhoneCaptcha,
  sendBindPhoneCaptcha,
  authPhoneCaptcha,
  sendEmailCaptcha,
  modifyBaseInfo,
} from '@/services/center';

export interface PersonalInfoProps {
  avatar: string;
  completenessRate: number;
  email: string;
  isLogoff: number;
  mobile: string;
  registerTime: string;
  role: number;
  userId: number;
  username: string;
  password: string;
  thirdPartyBindTime: string;
  thirdParty: string;

  address: string;
  commonProcessor: string;
  industry: string;
  profession: string;
  realName: string;
  website: string;
}

export interface CenterModelState {
  personalInfo: PersonalInfoProps;
}

export interface CenterModelType {
  namespace: 'center';
  state: CenterModelState;
  effects: {
    getPersonalInfo: Effect;
    modifyMobile: Effect;
    modifyAvatar: Effect;
    modifyPwd: Effect;
    sendAuthPhoneCaptcha: Effect;
    modifyEmail: Effect;
    sendBindPhoneCaptcha: Effect;
    authPhoneCaptcha: Effect;
    sendEmailCaptcha: Effect;
    modifyBaseInfo: Effect;
  };
  reducers: {
    save: Reducer<CenterModelState>;
  };
  subscriptions: { setup: Subscription };
}

const CenterModel: CenterModelType = {
  namespace: 'center',
  state: {
    personalInfo: {
      password: '',
      avatar: '',
      completenessRate: 0,
      email: '',
      isLogoff: 1,
      mobile: '',
      registerTime: '',
      role: 1,
      userId: 0,
      username: '',
      thirdPartyBindTime: '',
      thirdParty: '',

      address: '',
      industry: '',
      realName: '',
      profession: '',
      commonProcessor: '',
      website: '',
    },
  },

  effects: {
    *getPersonalInfo({}, { put, call }) {
      const response = yield call(getPersonalInfo);
      if (response?.state === 1000) {
        yield put({
          type: 'save',
          payload: {
            personalInfo: response.data,
          },
        });
      }
    },
    *sendBindPhoneCaptcha({ payload, callback }, { put, call }) {
      const response = yield call(sendBindPhoneCaptcha, payload);
      callback(response);
    },
    *sendAuthPhoneCaptcha({ callback }, { put, call }) {
      const response = yield call(sendAuthPhoneCaptcha);
      callback(response);
    },

    *sendEmailCaptcha({ payload, callback }, { put, call }) {
      const response = yield call(sendEmailCaptcha, payload);
      callback(response);
    },
    *authPhoneCaptcha({ payload, callback }, { put, call }) {
      const response = yield call(authPhoneCaptcha, payload);
      callback(response);
    },

    *modifyMobile({ payload, callback }, { put, call }) {
      const response = yield call(modifyMobile, payload);
      callback(response);
    },
    *modifyAvatar({ payload, callback }, { put, call }) {
      const response = yield call(modifyAvatar, payload);
      callback(response);
    },
    *modifyPwd({ payload, callback }, { put, call }) {
      const response = yield call(modifyPwd, payload);
      callback(response);
    },
    *modifyEmail({ payload, callback }, { put, call }) {
      const response = yield call(modifyEmail, payload);
      callback(response);
    },
    *modifyBaseInfo({ payload, callback }, { put, call }) {
      const response = yield call(modifyBaseInfo, payload);
      callback(response);
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/center') {
          dispatch({
            type: 'getPersonalInfo',
          });
        }
      });
    },
  },
};

export default CenterModel;
