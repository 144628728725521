import { Effect, Reducer, Subscription } from 'umi';
import _ from 'lodash';
import {
  addComment,
  getComment,
  likeComment,
  getTestcaseInfo,
  uploadTestProgram,
  findUserbin,
  deleteUserbin,
} from '@/services/imageDetail';
import { getPersonalInfo } from '@/services/center';
import { PersonalInfoProps } from '@/models/center';
import { message } from 'antd';

// WebSocket 指令类型
export enum MessageType {
  PAUSE = 'skyeye_pause',
  STOP = 'skyeye_quit',
  START = 'skyeye_run',
  RESTART = '',
  CLONE = '',
  CONNECT = 'connect_telnet',
  RECEIVE_MSG = 'term_output',
  PUSH_MSG = 'term_input',
  CLOSE_TIP = 'closetip',
}

export interface SocketMessageData {
  // 内容
  content: object | undefined;
  // 输出端口
  termport: string;
}

// Websocket 消息格式
export interface SocketMessage {
  // 版本
  version?: string;
  // 指令
  operate: MessageType;
  // 用户ID
  uid?: string;
  data?: SocketMessageData;
  // 镜像ID,建立连接时发送
  testcaseid?: number;
  [name: string]: object | string | number | undefined;
}

// 评论消息格式
export interface CommentResponse {
  children: Array<CommentResponse>;
  // 评论内容
  context: string;
  // 头像ID
  headId: string;
  // 评论ID
  id: number;
  // 是否点赞
  isLike: boolean;
  // 点赞数量
  likesNum: number;
  // 1普通用户 2Vip用户 3企业用户 4运营管理
  role: number;
  // 用户ID
  uid: number;
  // 用户名
  username: string;
  // 时间
  commentTime: number;
}

//串口信息
export interface ShellItem {
  id: number;
  // 标签名称
  name: string;
  // 属于哪个窗口
  belong: number;
  // 历史记录
  history: Array<any>;
  // 是否是当前显示对象
  show: boolean;
}

// 镜像信息
export interface ImageInfo {
  // 架构
  archName: string;
  // 处理器
  coreName: string;
  // 主频
  basicFrequency: string;
  // 操作系统
  operatingSystem: string;
  // 型号
  socName: string;
}

// 用户测试文件
export interface UserBin {
  binFileId: number;
  fileName: string;
  uploadTime: string;
}

export interface ImageDetailModelState {
  websocketConn: WebSocket | undefined;
  // 串口显示窗口
  shells: Array<ShellItem>;
  // 每个输出串口保持的最大记录数
  historyMaxLen: number;
  // 当前选中的窗口
  activeShell: number;
  // 评论
  comments: Array<CommentResponse>;
  // 用户信息
  user: PersonalInfoProps;
  testcaseId: number;
  // ws是否连接
  isStart: boolean;
  // 全屏
  fullScreen: boolean;
  // 连接状态改变，用于更新Xterm
  websocketState: boolean;
  // 镜像问题提示Modal显示
  noticeModalVisible: boolean;
  // 镜像信息
  imageInfo: ImageInfo | undefined;
  // 被测程序-当前测试程序
  currentTestProgram: number;
  testPrograms: Array<UserBin>;
  // 临时选中文件
  selectTestPrograms: number;
  // 需要登录提示框
  requireLoginVisible: boolean;
}

export interface ImageDetailModelType {
  namespace: 'imageDetail';
  state: ImageDetailModelState;
  effects: {
    init: Effect;
    getUserInfo: Effect;
    getTermInfo: Effect;
    getComment: Effect;
    addComment: Effect;
    likeComment: Effect;
    reflashComment: Effect;
    onStart: Effect;
    onPause: Effect;
    onStop: Effect;
    onRestart: Effect;
    onChangeActive: Effect;
    dispatchResizeEvent: Effect;
    uploadFile: Effect;
    getUserBin: Effect;
    deleteSelectTestProgram: Effect;
  };
  reducers: {
    save: Reducer<ImageDetailModelState>;
    editShellItemByid: Reducer<ImageDetailModelState>;
    switchShellShow: Reducer<ImageDetailModelState>;
    appendShellHistoryById: Reducer<ImageDetailModelState>;
    appendShellHistoryByName: Reducer<ImageDetailModelState>;
  };
  subscriptions: {
    setup: Subscription;
  };
}

export function encodeSocketMsg(data: SocketMessage) {
  return JSON.stringify(data);
}

export function decodeSocketMsg(data: string) {
  if (data[0] === '{') {
    return JSON.parse(data);
  } else {
    return undefined;
  }
}

const ImageDetailModel: ImageDetailModelType = {
  namespace: 'imageDetail',

  state: {
    websocketConn: undefined,
    shells: [],
    historyMaxLen: 1000,
    activeShell: 0,
    comments: [],
    user: {},
    testcaseId: 1,
    isStart: false,
    fullScreen: false,
    websocketState: false,
    noticeModalVisible: false,
    imageInfo: undefined,
    currentTestProgram: -1,
    testPrograms: [],
    selectTestPrograms: -1,
    requireLoginVisible: false,
  },

  effects: {
    *init({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          websocketConn: undefined,
          shells: [],
          historyMaxLen: 1000,
          activeShell: 0,
          comments: [],
          user: {},
          testcaseId: 1,
          isStart: false,
          fullScreen: false,
          websocketState: false,
          noticeModalVisible: false,
          imageInfo: undefined,
          currentTestProgram: -1,
          testPrograms: [],
          selectTestPrograms: -1,
          requireLoginVisible: false,
        },
      });
    },
    // 获取用户信息
    *getUserInfo({ payload }, { call, put }) {
      const response = yield call(getPersonalInfo);
      yield put({
        type: 'save',
        payload: {
          user: response?.data,
        },
      });
    },
    // 获取串口信息
    *getTermInfo({ payload }, { call, put }) {
      const res = yield call(getTestcaseInfo, {
        testcaseId: payload.testcaseId,
      });
      if (res?.state === 1000) {
        const { data } = res;
        const { termlist } = data;
        const {
          archName,
          coreName,
          basicFrequency,
          operatingSystem,
          socName,
        } = data;
        yield put({
          type: 'save',
          payload: {
            shells: termlist.map((item: any, index: number) => {
              return {
                id: index,
                name: item,
                belong: index >= termlist.length / 2 ? 1 : 0,
                show:
                  index === 0 ||
                  (index >= termlist.length / 2 &&
                    index === Math.round(termlist.length / 2)),
                history: [],
              };
            }),
            imageInfo: {
              archName,
              coreName,
              basicFrequency,
              operatingSystem,
              socName,
            },
          },
        });
      } else {
        yield put({
          type: 'save',
          payload: {
            noticeModalVisible: true,
          },
        });
      }
      // yield put({
      //   type: 'save',
      //   payload: {
      //     shells: [
      //       {
      //         id: 0,
      //         name: '窗口1',
      //         belong: 0,
      //         history: [],
      //         show: true,
      //         order: 0,
      //       },
      //       {
      //         id: 1,
      //         name: '窗口2',
      //         belong: 0,
      //         socketConnect: null,
      //         show: true,
      //         order: 0,
      //       },
      //       {
      //         id: 2,
      //         name: '窗口3',
      //         belong: 1,
      //         socketConnect: null,
      //         show: false,
      //         order: 0,
      //       },
      //       {
      //         id: 4,
      //         name: '窗口4',
      //         belong: 1,
      //         socketConnect: null,
      //         show: false,
      //         order: 0,
      //       },
      //     ],
      //   },
      // });
    },
    // 获取评论
    *getComment({ payload }, { call, put }) {
      const response = yield call(getComment, payload);
      if (response?.state === 1000) {
        yield put({
          type: 'save',
          payload: {
            comments: response.data.list,
          },
        });
      }
    },
    // 添加评论
    *addComment({ payload, callback, failback }, { call, put }) {
      const res = yield call(addComment, payload);
      const { state } = res;
      if (state === 1000) {
        message.success('评论成功');
        yield put({
          type: 'reflashComment',
        });
        callback();
      } else if (state === 3) {
        failback();
      } else {
        message.error(res.message || '添加评论失败');
      }
    },
    // 评论点赞
    *likeComment({ payload, callback }, { call, put, select }) {
      const response = yield call(likeComment, payload);
      if (response?.state === 1000) {
        callback();
        yield put({
          type: 'reflashComment',
        });
      }
    },
    // 更新评论
    *reflashComment({ payload }, { call, put, select }) {
      const { testcaseId } = yield select((_: any) => _.imageDetail);
      yield put({
        type: 'getComment',
        payload: {
          testcaseId,
        },
      });
    },
    // 如果Websocket连接中，则发送开始命令；否则建立新连接
    *onStart({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: {
          isStart: true,
        },
      });
    },
    *onPause({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: {
          isStart: false,
        },
      });
    },
    *onStop({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: {
          isStart: false,
        },
      });
    },
    *onRestart({ payload }, { call, put, select }) {},
    *onChangeActive({ payload }, { call, put, select }) {
      const { activeShell } = payload;
      yield put({
        type: 'save',
        payload,
      });
      yield put({
        type: 'switchShellShow',
        payload: {
          shell: {
            id: activeShell,
          },
        },
      });
    },
    *dispatchResizeEvent() {
      var event = new CustomEvent('xterm_resize');
      window.dispatchEvent(event);
    },
    *uploadFile({ payload, callback, failback }, { call, put, select }) {
      const { file } = payload;
      const res = yield call(uploadTestProgram, file);
      const { state } = res;
      if (state === 1000) {
        message.success('上传成功');
      } else if (state === 3) {
        failback();
      } else {
        message.error(res.message || '上传文件失败');
      }
      callback();
    },
    // 获取用户上传的测试程序
    *getUserBin({ payload }, { call, put }) {
      const res = yield call(findUserbin);
      const {
        state,
        data: { list },
      } = res;

      if (state === 1000) {
        yield put({
          type: 'save',
          payload: {
            testPrograms: list,
          },
        });
      } else if (state === 3) {
        yield put({
          type: 'save',
          payload: {
            requireLoginVisible: true,
          },
        });
      } else {
        message.error(res.message || '获取失败');
      }
    },
    *deleteSelectTestProgram({ payload }, { call, put, select }) {
      const { testPrograms } = yield select((_: any) => _.imageDetail);
      const { index } = payload;
      if (testPrograms[index]) {
        const res = yield call(deleteUserbin, {
          binfileId: testPrograms[index].binFileId,
        });
        if (res.state === 1000) {
          message.success('删除成功');
          put({
            type: 'save',
            payload: {
              currentTestProgram: 0,
              selectTestPrograms: 0,
            },
          });
          yield put({
            type: 'getUserBin',
          });
        } else {
          message.error('删除失败');
        }
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // 更改串口信息
    editShellItemByid(state, { payload }) {
      const { shell } = payload;

      const shells = state?.shells.map((item) => {
        if (shell.id === item.id) {
          return {
            ...item,
            ...shell,
          };
        }
        return item;
      });

      return {
        ...state,
        shells,
      } as ImageDetailModelState;
    },
    // 切换同一窗口的显示
    switchShellShow(state, { payload }) {
      const {
        shell: { id },
      } = payload;
      const belong = state?.shells[id]?.belong;

      const shells = state?.shells.map((item) => {
        if (id === item.id) {
          return {
            ...item,
            show: true,
          };
        } else if (item.belong === belong) {
          return {
            ...item,
            show: false,
          };
        }
        return item;
      });

      return {
        ...state,
        shells,
      } as ImageDetailModelState;
    },
    // 判断历史记录数量，再存入
    appendShellHistoryById(state, { payload }) {
      const { shell, data } = payload;

      const shells = state?.shells.map((item) => {
        if (shell.id === item.id) {
          if (item.history.length > state.historyMaxLen) {
            item.history.shift();
          }
          let tmpArr = item.history.map((item) => item);
          tmpArr.push(data);
          return {
            ...item,
            history: tmpArr,
          };
        }
        return item;
      });

      return {
        ...state,
        shells,
      } as ImageDetailModelState;
    },
    appendShellHistoryByName(state, { payload }) {
      const {
        shell: { name },
        data,
      } = payload;

      const shells = state?.shells.map((item) => {
        if (name === item.name) {
          if (item.history.length > state.historyMaxLen) {
            item.history.shift();
          }
          let tmpArr = item.history.map((item) => item);
          if (Array.isArray(data)) {
            tmpArr.push(...data);
          } else {
            tmpArr.push(data);
          }
          return {
            ...item,
            history: tmpArr,
          };
        }
        return item;
      });

      return {
        ...state,
        shells,
      } as ImageDetailModelState;
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        const testcaseId = Number.parseInt(
          history.location.pathname.split('/')[2],
        );
        if (/\/imageDetail/.test(pathname)) {
          dispatch({
            type: 'init',
          });
          dispatch({
            type: 'save',
            payload: {
              testcaseId,
            },
          });
          dispatch({
            type: 'getTermInfo',
            payload: {
              testcaseId: /\/imageDetail\/(.+)/.exec(pathname)?.pop(),
            },
          });
          dispatch({
            type: 'getComment',
            payload: {
              testcaseId,
            },
          });
          dispatch({
            type: 'getUserInfo',
          });
        }
      });
    },
  },
};

export default ImageDetailModel;
