import { Effect, Reducer, Subscription } from 'umi';
import {
  getInstructionsArch,
  getArchMappingCores,
  getFiltrationCores,
} from '@/services/images';

export interface ImagesModelState {
  name: string;
  instructionsArch: [];
  archMappingCores: any;
  filtrationCores: [];
}

export interface ImageModelType {
  namespace: 'images';
  state: ImagesModelState;
  effects: {
    getInstructionsArch: Effect;
    getArchMappingCores: Effect;
    getFiltrationCores: Effect;
  };
  reducers: {
    save: Reducer<ImagesModelState>;
  };
  subscriptions: { setup: Subscription };
}

const ImageModel: ImageModelType = {
  namespace: 'images',

  state: {
    name: '',
    instructionsArch: [],
    archMappingCores: {},
    filtrationCores: [],
  },

  effects: {
    *getInstructionsArch({}, { put, call }) {
      const response = yield call(getInstructionsArch);
      yield put({
        type: 'save',
        payload: {
          instructionsArch: response.data,
        },
      });
    },

    *getArchMappingCores({}, { put, call }) {
      const response = yield call(getArchMappingCores);
      yield put({
        type: 'save',
        payload: {
          archMappingCores: response.data,
        },
      });
    },

    *getFiltrationCores({ payload }, { put, call }) {
      const response = yield call(getFiltrationCores, payload);
      yield put({
        type: 'save',
        payload: {
          filtrationCores: response.data,
        },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/images') {
          dispatch({
            type: 'getInstructionsArch',
          });
          dispatch({
            type: 'getArchMappingCores',
          });
        }
      });
    },
  },
};

export default ImageModel;
